import React from 'react';
import { Box, Container, makeStyles, Card } from '@material-ui/core';

import Page from 'src/components/Page';

import ListHeader from './ListHeader';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));

const ListPage = ({
  title,
  breadcrumbs,
  actions,
  children,
  topFilters = null
}) => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title={title}>
      <Container maxWidth="xl">
        <ListHeader title={title} breadcrumbs={breadcrumbs} actions={actions} />
        {topFilters && (
          <Box mt={3}>
            <Card>{topFilters}</Card>
          </Box>
        )}
        {children}
      </Container>
    </Page>
  );
};

export default ListPage;
