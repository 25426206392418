export default {
  title: 'Buyer Detail',
  actions: [
    // {
    //   label: 'Edit',
    //   type: 'edit'
    // },
    // {
    //   label: 'Delete',
    //   type: 'delete'
    // }
  ],
  elements: [
    {
      type: 'detailsPanel',
      config: {
        actions: {
          fetch: {
            endpoint: 'buyers/{id}',
            method: 'GET',
            dataPath: 'data'
          }
        },
        sections: [
          {
            headerWidget: {
              widget: 'card',
              main: {
                widget: 'text',
                field: 'display_name',
                variant: 'h4'
              },
              secondary: {
                widget: 'text',
                field: 'user.email'
              }
            },
            fields: [
              {
                label: 'Registered on',
                field: 'created_at',
                widget: 'datetime'
              },
              {
                label: 'Onboarding completed on',
                field: 'onboarding_completed_at',
                widget: 'datetime'
              },
              {
                label: 'Country / Region',
                field: 'country_region'
              },
              {
                label: 'Phone number',
                field: 'phone'
              }
            ]
          },
          {
            title: 'Interests data',
            fields: [
              {
                label: 'Product type',
                field: 'product_type_interest'
              },
              {
                label: 'Store name',
                field: 'store_name'
              },
              {
                label: 'Store description',
                field: 'store_domain'
              }
            ]
          }
        ]
      }
    },
    {
      title: 'Order requests',
      type: 'dataTable',
      config: {
        url: 'order-requests',
        rowActions: [
          {
            type: 'detail'
          }
        ],
        defaultParams: {
          'filter[buyer_id]': '{resourceId}'
        },
        response: {
          mappers: {
            list: 'data'
          }
        },
        pagination: {
          mappers: {
            count: 'meta.total',
            perPage: 'meta.per_page'
          }
        },
        columns: [
          {
            label: 'To Supplier',
            field: 'supplier.screen_name'
          },
          {
            label: 'Type',
            field: 'type'
          },
          {
            label: 'Status',
            field: 'status'
          },
          {
            label: 'Created',
            field: 'created_at',
            widget: 'datetime'
          }
        ]
      }
    }
  ]
};
