import React from 'react';
import { getValue } from 'src/dataUtils';
import CheckIcon from '@material-ui/icons/Check';
import MinimizeOutlined from '@material-ui/icons/MinimizeOutlined';
import { green, grey } from '@material-ui/core/colors';

const Check = ({ data, field }) => {
  const value = getValue(data, field, false);

  const icon = value ? (
    <CheckIcon style={{ color: green[500] }} />
  ) : (
    <MinimizeOutlined style={{ color: grey[500] }} />
  );

  return icon;
};

export default Check;
