export const buyerListFilters = {
  fields: [
    {
      label: 'Search',
      name: 'filter[term]',
      size: 'small',
      span: 4,
      bgColor: 'white'
    }
  ]
};
