import React from 'react';

import { TextField as MuiTextField } from '@material-ui/core';

export default React.forwardRef(function TextField(
  { bgColor, type, fixedValue, namePrefix, autoComplete, ...rest },
  ref
) {
  const InputProps = {
    type,
    autoComplete,
    style: {
      background: bgColor
    }
  };

  const hasFixedValue = typeof fixedValue !== 'undefined';

  const finalValue = hasFixedValue ? fixedValue : rest.value;

  if (type === 'hidden') {
    return <input type="hidden" value={finalValue} />;
  }

  return (
    <MuiTextField
      InputProps={InputProps}
      {...rest}
      value={finalValue || ''}
      onChange={event => {
        const rawValue = event.target.value;
        let normalisedValue = rawValue;

        if (type === 'number') {
          normalisedValue = rawValue === '' ? '' : parseFloat(rawValue);
        }

        rest.onChange(normalisedValue);
      }}
    />
  );
});
