import business from './res/business';
import customer from './res/customer';
import bowltype from './res/bowltype';
import bowltransaction from './res/bowltransaction';
import product from './res/product';
import offer from './res/offer';

import sideMenu from './sideMenu';
import theme from './theme';

export default {
  branding: {
    logo: 'Aroundrs - Control Panel'
  },
  theme,
  api: {
    baseUrl: process.env.REACT_APP_AROUND_API_URL || 'http://localhost:8000/api'
  },
  auth: {
    tokenHeaderName: 'Token',
    login: {
      redirectTo: '/app/business/list',
      path: 'auth/token/login',
      response: {
        mappers: {
          token: 'authToken',
          user: 'user'
        }
      }
    },
    userProfile: {
      path: 'auth/users/me'
    }
  },
  sideMenu,
  resources: {
    business,
    customer,
    bowltype,
    bowltransaction,
    product,
    offer
  }
};
