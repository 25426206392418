import React from 'react';

import { get } from 'lodash';

import { Controller, useFormContext } from 'react-hook-form';
import FormHelperText from '@material-ui/core/FormHelperText';
import QuillField from './fields/QuillField';
import SwitchField from './fields/SwitchField';
import CheckboxField from './fields/CheckboxField';
import LazySelect from './fields/LazySelect';
import TreeField from './fields/TreeField';
import HasManyField from './fields/HasManyField';
import HasOneField from './fields/HasOneField';
import ImageField from './fields/ImageField';
import RelatedPickerField from './fields/RelatedPickerField';
import RadioGroup from './fields/RadioGroupField';
import TextField from './fields/TextField';
import SelectField from './fields/SelectField';
import TreeSelect from './fields/TreeSelect';
import GooglePlaceField from './fields/GooglePlaceField';
import SortableTree from './fields/SortableTree';

const TYPE_MAP = {
  text: TextField,
  richText: QuillField,
  switch: SwitchField,
  radioGroup: RadioGroup,
  checkbox: CheckboxField,
  lazySelect: LazySelect,
  tree: TreeField,
  hasMany: HasManyField,
  hasOne: HasOneField,
  image: ImageField,
  select: SelectField,
  relatedPicker: RelatedPickerField,
  treeSelect: TreeSelect,
  googlePlace: GooglePlaceField,
  sortableTree: SortableTree
};

const FormField = ({
  type,
  name,
  help,
  value,
  parentRecord,
  namePrefix = '',
  defaultValue = '',
  fixedValue,
  Wrapper,
  ...rest
}) => {
  const { errors, control } = useFormContext();

  const InputCmp = TYPE_MAP[type] || TextField;

  const extraInputProps = {
    variant: 'outlined',
    fullWidth: true,
    namePrefix,
    type,
    fixedValue,
    ...rest
  };

  if (
    [
      'lazySelect',
      'tree',
      'treeSelect',
      'image',
      'hasOne',
      'relatedPicker'
    ].includes(type)
  ) {
    extraInputProps.parentRecord = parentRecord;
  }

  if (['date', 'datetime-local'].includes(type)) {
    extraInputProps.InputLabelProps = { shrink: true };
  }

  const fullName = namePrefix ? `${namePrefix}.${name}` : name;

  // let showField = true;

  // const watchFields = watch();
  // const targetFieldName = namePrefix
  //   ? `${namePrefix}.${showIf.field}`
  //   : showIf.field;

  // if (get(watchFields, targetFieldName) !== showIf.value) {
  //   showField = false;
  // }

  // if (showIf) {
  // }

  // if (!showField) {
  //   return null;
  // }

  let fieldContent;

  let fieldDefaultValue = defaultValue;

  if (type === 'switch') {
    fieldDefaultValue = !!fieldDefaultValue;
  }

  if (type === 'hasMany' || type === 'hasOne') {
    fieldContent = (
      <InputCmp
        {...rest}
        defaultValue={fieldDefaultValue}
        name={fullName}
        singleName={name}
        parentRecord={parentRecord}
      />
    );
  } else {
    const error = get(errors, fullName);

    fieldContent = (
      <Controller
        name={fullName}
        control={control}
        render={({ ...inputProps }) => {
          return (
            <React.Fragment>
              <InputCmp
                {...inputProps}
                {...extraInputProps}
                error={!!error}
                helperText={error ? error.message : null}
              />
              {help && <FormHelperText>{help}</FormHelperText>}
            </React.Fragment>
          );
        }}
        defaultValue={
          typeof fixedValue !== 'undefined'
            ? fixedValue
            : value || fieldDefaultValue
        }
      />
    );
  }

  if (Wrapper) {
    return <Wrapper.Cmp {...(Wrapper.props || {})}>{fieldContent}</Wrapper.Cmp>;
  }

  return fieldContent;
};

export default FormField;
