export default {
  title: 'Conversation Detail',
  actions: [
    {
      label: 'Delete',
      type: 'delete'
    }
  ],
  elements: [
    {
      type: 'detailsPanel',
      config: {
        actions: {
          fetch: {
            endpoint: 'conversation/{id}',
            method: 'GET',
            dataPath: 'data'
          }
        },
        sections: [
          {
            fields: [
              {
                label: 'To Supplier',
                widget: 'card',
                main: 'supplier.screen_name',
                image: {
                  field: 'supplier.logo_url',
                  width: 40,
                  height: 40
                }
              },
              {
                label: 'From Buyer',
                field: 'buyer.display_name'
              },
              {
                label: 'Created',
                field: 'created_at',
                widget: 'datetime'
              },
              {
                label: 'Sent at',
                field: 'sent_at',
                widget: 'datetime',
                emptyVal: 'Not sent yet (draft)'
              }
            ]
          },
          {
            fields: [
              {
                label: 'Notes',
                field: 'note',
                widget: 'htmlContent',
                lines: 10
              }
            ]
          }
        ]
      }
    },
    {
      title: 'Items in this request',
      type: 'dataTable',
      config: {
        url: 'order-request-items',
        response: {
          mappers: {
            list: 'data'
          }
        },
        defaultParams: {
          'filter[order_request_id]': '{resourceId}'
        },
        maxHeight: 550,
        columns: [
          {
            label: 'Quantity',
            field: 'quantity',
            align: 'center'
          },
          {
            label: 'Product',
            widget: 'card',
            main: 'product.name',
            image: {
              field: 'product.listing_image_url',
              width: 80,
              height: 80
            },
            primary: {
              field: 'product.name'
            },
            secondary: {
              widget: 'identifierLabel',
              field: 'product.sku'
            }
          },
          {
            label: 'Notes',
            field: 'note'
          }
        ]
      }
    }
  ]
};
