import { buyerListFilters } from './shared';
export default {
  title: 'Elenco Buyers',

  elements: [
    {
      type: 'dataTable',
      config: {
        url: 'buyers',
        defaultParams: {
          sort: '-created_at'
        },
        rowActions: [
          {
            type: 'detail'
          },
          {
            type: 'delete'
          }
        ],
        response: {
          mappers: {
            list: 'data'
          }
        },
        filters: {
          ...buyerListFilters,
          fields: [
            ...buyerListFilters.fields,
            {
              label: 'Iscritti dal',
              name: 'filter[subscribed_from]',
              span: 3,
              type: 'date'
            },
            {
              label: 'Al',
              name: 'filter[subscribed_to]',
              span: 3,
              type: 'date'
            }
          ]
        },
        pagination: {
          mappers: {
            count: 'meta.total',
            perPage: 'meta.per_page'
          }
        },
        columns: [
          {
            label: 'Name',
            widget: {
              widget: 'card',
              main: {
                widget: 'text',
                field: 'display_name'
              },
              secondary: {
                widget: 'text',
                field: 'user.email',
                variant: 'body2'
              }
            }
          },
          {
            label: 'Created',
            field: 'created_at',
            widget: 'datetime',
            sortable: true
          },
          {
            label: 'Onboarding completed',
            field: 'has_completed_onboarding',
            widget: 'check'
          },
          {
            label: 'Country / Region',
            field: 'country_region'
          },
          {
            label: 'Interest',
            field: 'product_type_interest'
          },
          {
            label: '# Order requests',
            field: 'order_requests_count',
            sortable: true
          }
        ]
      }
    }
  ]
};
