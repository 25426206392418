import two_cols_panel from './blocks/two_cols_panel';
import two_cols_text from './blocks/two_cols_text';
import hero from './blocks/hero';
import hero_with_actions from './blocks/hero_with_actions';
import hero_with_box from './blocks/hero_with_box';
import hero_with_spaces from './blocks/hero_with_spaces';
import asymmetric_header from './blocks/asymmetric_header';
import broken_cols from './blocks/broken_cols';
import info_box from './blocks/info_box';
import newsletter from './blocks/newsletter';
import new_arrivals from './blocks/new_arrivals';
import product_carousel from './blocks/product_carousel';
import supplier_carousel from './blocks/supplier_carousel';
import generic_carousel from './blocks/generic_carousel';
import hero_carousel from './blocks/hero_carousel';
import text_carousel from './blocks/text_carousel';
import image_full from './blocks/image_full';
import text_wall from './blocks/text_wall';

export default {
  two_cols_panel: two_cols_panel,
  two_cols_text: two_cols_text,
  hero: hero,
  hero_with_actions: hero_with_actions,
  hero_with_box: hero_with_box,
  hero_with_spaces: hero_with_spaces,
  asymmetric_header: asymmetric_header,
  broken_cols: broken_cols,
  info_box: info_box,
  newsletter: newsletter,
  new_arrivals: new_arrivals,
  product_carousel: product_carousel,
  supplier_carousel: supplier_carousel,
  generic_carousel: generic_carousel,
  hero_carousel: hero_carousel,
  text_carousel: text_carousel,
  image_full: image_full,
  text_wall: text_wall
};
