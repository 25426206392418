import fieldset from './fieldset';
import configs from '../../configs';

export default {
  title: 'Edit Category',
  elements: [
    {
      type: 'editForm',
      config: {
        actions: {
          fetch: {
            endpoint: 'categories/{id}',
            method: 'GET',
            dataPath: 'data'
          },
          save: {
            endpoint: 'categories/{id}',
            method: 'PUT'
          }
        },
        fieldsets: [fieldset],
        versionFormView: {
          versions: configs.versions,
          type: 'editForm',
          config: {
            actions: {
              fetch: {
                endpoint: 'categories/{id}',
                method: 'GET',
                dataPath: 'data',
                params: {
                  lang: '{versionId}'
                }
              },
              save: {
                endpoint: 'categories/{id}',
                method: 'PUT',
                params: {
                  lang: '{versionId}'
                }
              }
            },
            fieldsets: [
              {
                sections: [
                  {
                    title: 'Main details',
                    fields: [
                      {
                        label: 'Name',
                        name: 'name'
                      },
                      {
                        label: 'Description',
                        name: 'description',
                        type: 'richText'
                      }
                    ]
                  },
                  {
                    title: 'SEO tags',
                    fields: [
                      {
                        label: 'Meta title',
                        name: 'meta_title'
                      },
                      {
                        label: 'Meta description',
                        name: 'meta_description',
                        multiline: true
                      }
                    ]
                  }
                ]
              }
            ]
          }
        }
      }
    }
  ]
};
