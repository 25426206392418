import { supplierListFilters } from './shared';
export default {
  title: 'Elenco Supplier',

  elements: [
    {
      type: 'dataTable',
      config: {
        url: 'suppliers',
        defaultParams: {
          sort: '-created_at'
        },
        response: {
          mappers: {
            list: 'data'
          }
        },
        filters: {
          ...supplierListFilters,
          fields: [
            ...supplierListFilters.fields,
            {
              label: 'Iscritti dal',
              name: 'filter[subscribed_from]',
              span: 3,
              type: 'date'
            },
            {
              label: 'Al',
              name: 'filter[subscribed_to]',
              span: 3,
              type: 'date'
            }
          ]
        },
        tabs: [
          {
            id: 'published',
            label: 'Published',
            requestParams: {
              'filter[published]': 1
            }
          },
          {
            id: 'unpublished',
            label: 'Unpublished',
            requestParams: {
              'filter[published]': 0
            }
          },
          {
            id: 'pending_onboard',
            label: 'Pending onboard',
            requestParams: {
              'filter[pending_onboard]': 1
            }
          }
        ],
        pagination: {
          mappers: {
            count: 'meta.total',
            perPage: 'meta.per_page'
          }
        },
        columns: [
          {
            label: 'Name',
            sortable: true,
            widget: 'card',
            main: {
              widget: 'text',
              field: 'screen_name',
              variant: 'h5'
            },
            image: {
              widget: 'logo',
              field: 'logo_url'
            },
            secondary: {
              field: 'user.email'
            },
            tertiary: {
              widget: 'path',
              field: 'main_category_tree',
              itemNamePath: 'name'
            }
          },
          {
            label: 'Current subscription',
            field: 'activeSubscription.price.product.name'
          },
          {
            label: 'Locality',
            field: 'company_locality'
          },
          {
            label: 'Created',
            field: 'created_at',
            widget: 'datetime',
            sortable: true
          },
          {
            label: 'Onboarding completed',
            field: 'has_completed_onboarding',
            widget: 'check'
          },
          {
            label: 'Referral',
            field: 'referral'
          },
          {
            label: 'Published',
            field: 'published',
            widget: 'check'
          }
        ]
      }
    }
  ]
};
