import { supplierListFilters } from './shared';

export default {
  title: 'Statistiche Suppliers',
  listActions: [],
  elements: [
    {
      type: 'dataTable',
      config: {
        url: 'supplier-stats',
        enableViewAction: true,
        rowActions: [],
        defaultParams: {
          sort: '-order_requests_count'
        },
        response: {
          mappers: {
            list: 'data'
          }
        },
        filters: {
          ...supplierListFilters,
          fields: [
            ...supplierListFilters.fields,
            {
              label: 'Dal',
              name: 'start',
              span: 3,
              type: 'date'
            },
            {
              label: 'Al',
              name: 'end',
              span: 3,
              type: 'date'
            }
          ]
        },
        tabs: [],
        pagination: {
          mappers: {
            count: 'meta.total',
            perPage: 'meta.per_page'
          }
        },
        columns: [
          {
            label: 'Name',
            sortable: true,
            widget: 'card',
            main: {
              widget: 'text',
              field: 'screen_name',
              variant: 'h5'
            }
          },
          {
            label: 'Order requests',
            field: 'order_requests_count',
            sortable: true
          },
          {
            label: 'Conversations',
            field: 'conversations_count',
            sortable: true
          },
          {
            label: 'Overall profile views',
            field: 'profile_view_count'
          }
        ]
      }
    }
  ]
};
