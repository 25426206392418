import React from 'react';
import { isString, isPlainObject, isArray } from 'lodash';

import Widget from './Widget';
import Text from './Text';

const Slot = ({ data, attr, widgetType = null }) => {
  if (isString(attr)) {
    return <Text data={data} field={attr} />;
  }

  if (isArray(attr)) {
    debugger;
    return <Widget data={data} attrs={{ widget: widgetType, ...attr }} />;
  }

  if (isPlainObject(attr)) {
    return <Widget data={data} attrs={{ widget: widgetType, ...attr }} />;
  }

  console.error(`Unkown attr value: "${attr}"`, data);

  return null;
};

export default Slot;
