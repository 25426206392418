export default {
  title: 'Supplier Blog posts',

  elements: [
    {
      type: 'dataTable',
      config: {
        url: 'supplier-blog-posts',
        defaultParams: {
          sort: '-created_at'
        },
        response: {
          mappers: {
            list: 'data'
          }
        },
        pagination: {
          mappers: {
            count: 'meta.total',
            perPage: 'meta.per_page'
          }
        },
        columns: [
          {
            label: 'Title',
            field: 'title'
          },
          {
            label: 'Created',
            field: 'created_at',
            widget: 'datetime',
            sortable: true
          },
          {
            label: 'Published',
            field: 'published',
            widget: 'publishedIcon'
          }
        ]
      }
    }
  ]
};
