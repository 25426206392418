import blockTypes from '../content/blockTypes';

export const contentBlocksField = {
  name: 'content_blocks',
  type: 'hasMany',
  includeIds: false,
  collapsible: true,
  layout: 'verticalList',
  typeSelector: {
    typeFieldName: 'block_type',
    addLabel: 'Add new block:',
    options: [
      blockTypes.text_wall,
      blockTypes.image_full,
      blockTypes.two_cols_panel,
      blockTypes.two_cols_text,
      blockTypes.hero,
      blockTypes.hero_with_actions,
      blockTypes.hero_with_box,
      blockTypes.hero_with_spaces,
      blockTypes.asymmetric_header,
      blockTypes.info_box,
      blockTypes.product_carousel,
      blockTypes.supplier_carousel,
      blockTypes.generic_carousel,
      blockTypes.newsletter,
      blockTypes.new_arrivals,
      blockTypes.broken_cols,
      blockTypes.hero_carousel,
      blockTypes.text_carousel
    ]
  }
};

export const main = {
  title: 'Main info',
  initialValues: {},
  sections: [
    {
      fields: [
        {
          name: 'name',
          label: 'Page name'
        },
        {
          name: 'codename',
          label: 'Codename (internal identifier)'
        },
        {
          name: 'published',
          label: 'Published',
          type: 'switch'
        }
      ]
    },
    {
      title: 'Content blocks',
      fields: [contentBlocksField]
    }
  ]
};
