import * as fieldset from './fieldset';

export default {
  title: 'Edit Supplier Blog Post',
  elements: [
    {
      title: 'Main',
      type: 'editForm',
      config: {
        actions: {
          fetch: {
            endpoint: 'supplier-blog-posts/{id}',
            method: 'GET',
            dataPath: 'data',
            transform: values => {
              return {
                ...values,
                content: values.content || []
              };
            }
          },
          save: {
            endpoint: 'supplier-blog-posts/{id}',
            method: 'PUT'
          }
        },
        fieldsets: [fieldset.main]
      }
    }
  ]
};
