export default {
  title: 'Custom projects',
  listActions: [],
  elements: [
    {
      type: 'dataTable',
      listActions: [],
      config: {
        url: 'custom-projects',
        enableViewAction: true,
        rowActions: [
          {
            type: 'delete'
          }
        ],
        defaultParams: {
          sort: '-created_at'
        },
        response: {
          mappers: {
            list: 'data'
          }
        },
        pagination: {
          mappers: {
            count: 'meta.total',
            perPage: 'meta.per_page'
          }
        },
        columns: [
          {
            label: 'Title',
            widget: 'card',
            main: {
              widget: 'text',
              field: 'title'
            },
            secondary: {
              field: 'status',
              widget: 'identifierLabel'
            }
          },
          {
            label: 'Categories',
            widget: 'tagList',
            field: 'directory_categories',
            itemNamePath: 'name'
          },
          {
            label: 'From Buyer',
            field: 'buyer.display_name'
          },
          {
            label: 'Created at',
            field: 'created_at',
            widget: 'datetime',
            sortable: true
          },
          {
            label: 'Sent at',
            field: 'sent_at',
            widget: 'datetime',
            sortable: true
          },
          {
            label: 'Conversations',
            field: 'conversations_count'
          }
        ]
      }
    }
  ]
};
