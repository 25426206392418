import { colors } from '@material-ui/core';
import { softShadows } from './shadows';

export default {
  responsiveFontSizes: true,
  overrides: {
    MuiInputBase: {
      input: {
        '&::placeholder': {
          opacity: 1,
          color: colors.blueGrey[600]
        }
      }
    }
  },
  palette: {
    type: 'light',
    action: {
      active: colors.blueGrey[600]
    },
    background: {
      default: colors.common.white,
      dark: '#f4f6f8',
      paper: colors.common.white
    },
    primary: {
      // main: '#00695f'
      main: '#125c66'
    },
    secondary: {
      main: '#00535e'
      // main: '#f50057'
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600]
    }
  },
  shadows: softShadows
};
