export default {
  title: 'Conversation List',
  listActions: [],
  elements: [
    {
      type: 'dataTable',
      config: {
        url: 'conversations',
        enableViewAction: true,
        rowActions: [
          {
            type: 'detail'
          },
          {
            type: 'delete'
          }
        ],
        defaultParams: {
          sort: '-created_at'
        },
        response: {
          mappers: {
            list: 'data'
          }
        },
        pagination: {
          mappers: {
            count: 'meta.total',
            perPage: 'meta.per_page'
          }
        },
        columns: [
          {
            label: 'Started at',
            widget: 'card',
            main: {
              widget: 'text',
              field: 'title'
            },
            secondary: {
              field: 'created_at',
              widget: 'datetime'
            }
          },
          {
            label: 'Supplier',
            widget: {
              widget: 'card',
              main: {
                widget: 'text',
                field: 'supplier.screen_name'
              },
              image: {
                widget: 'logo',
                field: 'supplier.logo_url',
                width: 40,
                height: 40
              }
            }
          },
          {
            label: 'Buyer',
            field: 'buyer.display_name'
          },
          {
            label: 'Num of messages',
            field: 'total_messages',
            widget: 'text'
          }
        ]
      }
    }
  ]
};
