import React from 'react';
import { get } from 'lodash';
import ListPage from 'src/components/pages/ListPage';

import { useParams } from 'react-router';
import ViewElements from '../ViewElements';

const ListView = ({ config }) => {
  const { appName, resourceSlug } = useParams();

  const breadcrumbs = [];

  const listActions = get(config, 'listActions', [
    {
      type: 'create',
      to: `/${appName}/app/${resourceSlug}/create`,
      label: 'Aggiungi Record'
    }
  ]);

  return (
    <ListPage
      title={config.title}
      breadcrumbs={breadcrumbs}
      actions={listActions}
    >
      <ViewElements resourceSlug={resourceSlug} elements={config.elements} />
    </ListPage>
  );
};

export default ListView;
