import React, { useEffect } from 'react';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Grid,
  Typography
} from '@material-ui/core';
import { get } from 'lodash';

import expandString from 'expand-template';

import useAction from 'src/hooks/useAction';

import { extract } from 'src/dataUtils';
import Widget from 'src/generics/widgets/Widget';

const stringExpander = expandString();

const DetailsPanel = ({ config, resourceId }) => {
  const actions = {
    fetch: useAction({
      url: stringExpander(config.actions.fetch.endpoint, {
        id: resourceId
      }),
      method: config.actions.fetch.method,
      params: get(config, 'actions.fetch.params', {})
    })
  };

  useEffect(() => {
    actions.fetch.execute();
    // FIXME: needed cause actions are recreated!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const detailsData = actions.fetch.data
    ? config.actions.fetch.dataPath
      ? extract(
          actions.fetch.data,
          config.actions.fetch.dataPath,
          actions.fetch.data
        )
      : actions.fetch.data
    : null;

  const { sections } = config;

  const sectionContent = detailsData
    ? sections.map((section, sectionIndex) => {
        return (
          <Box component={Card} mb={2} key={sectionIndex}>
            {section.headerWidget && (
              <React.Fragment>
                <CardHeader
                  title={
                    <Widget data={detailsData} attrs={section.headerWidget} />
                  }
                ></CardHeader>
                <Divider />
              </React.Fragment>
            )}
            {section.title && (
              <React.Fragment>
                <CardHeader title={section.title}></CardHeader>
                <Divider />
              </React.Fragment>
            )}
            <CardContent>
              {section.fields.map((field, index) => {
                return (
                  <Box key={index}>
                    <Grid container spacing={4}>
                      <Grid item sm={2}>
                        <Typography
                          align="right"
                          variant="overline"
                          color="textSecondary"
                        >
                          {field.label}:
                        </Typography>
                      </Grid>
                      <Grid item sm={10}>
                        <Widget data={detailsData} attrs={field} />
                      </Grid>
                    </Grid>
                  </Box>
                );
              })}
            </CardContent>
          </Box>
        );
      })
    : null;

  return <React.Fragment>{sectionContent}</React.Fragment>;
};

export default DetailsPanel;
