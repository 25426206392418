import React from 'react';
import { isArray, get } from 'lodash';

import PublishedIcon from './PublishedIcon';
import IdentifierLabel from './IdentifierLabel';
import Thumb from './Thumb';
import Datetime from './Datetime';
import Logo from './Logo';
import Card from './Card';
import Text from './Text';
import HtmlContent from './HtmlContent';
import Path from './Path';
import QRCode from './QRCode';
import Url from './Url';
import Check from './Check';
import MonetaryAmount from './MonetaryAmount';
import TagList from './TagList';

const WIDGET_MAP = {
  card: Card,
  logo: Logo,
  publishedIcon: PublishedIcon,
  identifierLabel: IdentifierLabel,
  thumb: Thumb,
  datetime: Datetime,
  text: Text,
  htmlContent: HtmlContent,
  path: Path,
  qrCode: QRCode,
  url: Url,
  check: Check,
  monetaryAmount: MonetaryAmount,
  tagList: TagList
};

const Widget = ({ attrs, data }) => {
  let finalAttrs = attrs;

  if (finalAttrs.widget && isArray(finalAttrs.widget)) {
    const matchingWidget = finalAttrs.widget.find(rule => get(data, rule.if));
    if (!matchingWidget) {
      console.error(`No matching widget for rules`, finalAttrs.widget);
      return null;
    }

    finalAttrs = matchingWidget.then;
  }

  if (!finalAttrs.widget) {
    return <Text data={data} {...finalAttrs} />;
  }

  const Cmp = WIDGET_MAP[finalAttrs.widget];

  if (!Cmp) {
    console.error(`Invalid Widget type: ${finalAttrs.widget}`, finalAttrs);
    return null;
  }

  return <Cmp data={data} {...finalAttrs} />;
};

export default Widget;
