import product from './res/product';
import category from './res/category';
import supplier from './res/supplier';
import supplierStat from './res/supplierStat';
import orderRequest from './res/orderRequest';
import conversation from './res/conversation';
import message from './res/message';
import membershipProduct from './res/membershipProduct';
import membershipPrice from './res/membershipPrice';
import membershipCheckout from './res/membershipCheckout';
import membershipCoupon from './res/membershipCoupon';
import page from './res/page';
import blogPost from './res/blogPost';
import supplierBlogPost from './res/supplierBlogPost';
import buyer from './res/buyer';
import customProject from './res/customProject';

import sideMenu from './sideMenu';
import theme from './theme';

export default {
  branding: {
    logo: 'VIAMADEINITALY'
  },
  theme,
  api: {
    baseUrl:
      process.env.REACT_APP_VIAMADEINITALY_API_URL ||
      'http://localhost:8000/api'
  },
  auth: {
    tokenHeaderName: 'Bearer',
    login: {
      redirectTo: '/app/product/list',
      path: 'auth/token',
      response: {
        mappers: {
          token: 'token',
          user: 'user'
        }
      }
    },
    userProfile: {
      path: 'auth/user'
    }
  },
  sideMenu,
  resources: {
    product,
    category,
    supplier,
    supplierStat,
    buyer,
    orderrequest: orderRequest,
    conversation: conversation,
    message: message,
    membershipproduct: membershipProduct,
    membershipprice: membershipPrice,
    membershipcheckout: membershipCheckout,
    membershipcoupon: membershipCoupon,
    page,
    blogpost: blogPost,
    supplierblogpost: supplierBlogPost,
    customproject: customProject
  }
};
